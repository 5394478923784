<template>
    <div id="data-list-list-view" class="data-list-container" v-if="wills">
        <ToCheckTable
            :data="wills"
            :itemsPerPage="itemsPerPage"
            @itemsPerPage="setItemsPerPage"
            @search="search"
            @sort="sort"
        ></ToCheckTable>
        <vs-pagination
            :total="wills.number_of_pages"
            v-model="pageNumber"
        ></vs-pagination>
    </div>
</template>

<script>
import ToCheckTable from './Table'
import { http } from '@/services'

export default {
    name: 'WillsToCheck',
    components: {
        ToCheckTable,
    },
    mounted() {
        this.getPage()
    },
    watch: {
        pageNumber(value) {
            if (value) this.getPage()
        },
    },
    computed: {
        willsData() {
            if (this.wills && this.wills.data) return this.wills.data
            return []
        },
    },
    data() {
        return {
            wills: null,
            pageNumber: 1,
            itemsPerPage: 10,
            selected: null,
            queriedItems: 20,
            query: null,
            sortKey: 'paid_date',
            sortActive: 'asc',
        }
    },
    methods: {
        addNewData() {
            console.log('add new data')
        },
        setItemsPerPage(value) {
            this.itemsPerPage = value
            this.getPage()
        },
        setPageNumber(value) {
            this.pageNumber = value
            this.getPage()
        },
        getPage() {
            http.get('wills_list', {
                params: {
                    limit: 'to_check',
                    items_per_page: this.itemsPerPage,
                    page_number: this.pageNumber,
                    query: this.query,
                    sort: [this.sortKey, this.sortActive],
                },
            })
                .then((response) => {
                    this.wills = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        search(value) {
            this.query = value
            this.getPage()
        },
        sort(key, active) {
            this.sortKey = key
            this.sortActive = active
            this.getPage()
        },
    },
}
</script>

<style lang="scss">
#data-list-list-view {
    .vs-con-table {
        /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
        @media (max-width: 689px) {
            .vs-table--search {
                margin-left: 0;
                max-width: unset;
                width: 100%;

                .vs-table--search-input {
                    width: 100%;
                }
            }
        }

        @media (max-width: 461px) {
            .items-per-page-handler {
                display: none;
            }
        }

        @media (max-width: 341px) {
            .data-list-btn-container {
                width: 100%;

                .dd-actions,
                .btn-add-new {
                    width: 100%;
                    margin-right: 0 !important;
                }
            }
        }

        .product-name {
            max-width: 23rem;
        }

        .vs-table--header {
            display: flex;
            flex-wrap: wrap;
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            > span {
                display: flex;
                flex-grow: 1;
            }

            .vs-table--search {
                padding-top: 0;

                .vs-table--search-input {
                    padding: 0.9rem 2.5rem;
                    font-size: 1rem;

                    & + i {
                        left: 1rem;
                    }

                    &:focus + i {
                        left: 1rem;
                    }
                }
            }
        }

        .vs-table {
            border-collapse: separate;
            border-spacing: 0 1.3rem;
            padding: 0 1rem;

            tr {
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
                td {
                    padding: 20px;
                    &:first-child {
                        border-top-left-radius: 0.5rem;
                        border-bottom-left-radius: 0.5rem;
                    }
                    &:last-child {
                        border-top-right-radius: 0.5rem;
                        border-bottom-right-radius: 0.5rem;
                    }
                }
                td.td-check {
                    padding: 20px !important;
                }
            }
        }

        .vs-table--thead {
            th {
                padding-top: 0;
                padding-bottom: 0;

                .vs-table-text {
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
            th.td-check {
                padding: 0 15px !important;
            }
            tr {
                background: none;
                box-shadow: none;
            }
        }

        .vs-table--pagination {
            justify-content: center;
        }
    }
}
</style>
